<template>
  <v-main>
    <app-menu/>
    <div >
      <div class="text-center">
        <v-dialog v-model="isLoad" persistent width="300">
          <v-card color="primary" dark >
            <v-card-text>
              cargando
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card> 
        </v-dialog>
      </div> 
      <div v-if="isLoad==false"> 
        <v-container v-if="msgerror!=''" class="my-6 grey lighten-5"  >
          <v-alert  dense  outlined  type="error" > 
            {{ msgerror }}
          </v-alert>
        </v-container>

      <div v-if="isChangePassword ==false">
        <v-container v-if="existeUsuario==false" class="my-10"  >
          <v-card  height="500" width="500"  class="mx-auto iniciarsesion" elevation="8" style="margin:0 auto;border-radius:30px 0px ">  
            <v-container fill-height fluid> 
              <v-row align="center" justify="center"> 
                <v-card-title>¡Hola!</v-card-title>
                  <v-card-title>
                      Ingresa tu teléfono, correo electrónico ó usuario
                  </v-card-title>

                  <v-container style="width:90%" class="my-10 mx-auto">
                    <v-text-field  @keyup.enter.native="comprobarUsuario" v-model="user" 
                    label="celular, correo electrónico o usuario" filled outlined rounded dense ></v-text-field>
                  </v-container> 
                  <v-btn class="my-5 mx-auto overline" width="80%" large color="primary"  @click="comprobarUsuario" >
                    Continuar
                  </v-btn> 
              </v-row>
            </v-container>
          </v-card> 
        </v-container>

        <v-container v-else class="my-10 "  >
          <v-card height="500" width="500"  class="mx-auto" style="margin:0 auto;border-radius:30px 0px " elevation="8"> 
            <v-btn @click="returnUser()" text color="cyan" class="my-2 overline" >
              <v-icon dark left >mdi-arrow-left </v-icon>
              <span>Regresar</span> 
            </v-btn>

            <v-container v-if="existeUsuario==true" fill-height fluid> 
              <v-row align="center" justify="center"> 
                <v-card-title>¡Muy bien! Ahora Ingresa tu contraseña</v-card-title>
                <v-container style="width:90%" class="my-2 mx-auto"> 
                  <v-text-field name="user" prepend-icon="mdi-account" v-model="user" disabled
                  ></v-text-field> 
                </v-container>
                <v-container style="width:90%" class="my-2 mx-auto">
                  <v-text-field @keyup.enter.native="login" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPass ? 'text' : 'password'" label="Contraseña"
                      @click:append="showPassw" v-model="password"
                  ></v-text-field> 
                </v-container>
                <v-btn class="my-5 mx-auto overline" width="80%" large color="primary" @click="login" >
                  Continuar
                </v-btn> 
              </v-row>
            </v-container> 
          </v-card>
        </v-container>
      </div>
<!--   -->
        <v-container v-if="isChangePassword"   class="my-10"  > 
        <!-- <v-container    class="my-6"  > -->
          <!--  height="500"  -->
          <v-card width="500"  class="mx-auto" style="margin:0 auto;border-radius:30px 0px " elevation="8" > 
            <v-btn @click="returnUser()" text color="cyan"  class="my-2 overline" >
              <v-icon dark left >mdi-arrow-left </v-icon>
              <span>Regresar</span> 
            </v-btn>  
            <v-container v-if="codeValid==false" fill-height fluid>  
               <v-row align="center" justify="center"> 
                  <v-card-title >
                    <div class="">
                      Código de seguridad
                    </div>
                    </v-card-title>
                <v-container class="text-center">
                  <v-card-subtitle class="overline">
                    ¿Comó quieres que te enviemos tu código de seguridad?
                  </v-card-subtitle>
                  <v-row class="container mx-auto text-center">
                    <v-col cols="">
                      <v-icon class="iconWhats btn"  @click="envioWhatsapp(resuser.data.user)"  color="#31E697">mdi-whatsapp</v-icon>
                    </v-col>
                    <v-col cols="">
                      <v-icon class="iconMail" @click="envioCorreo(resuser.data.user)" color="red" >mdi-email</v-icon>
                    </v-col>
                  </v-row>
                  <br>
                </v-container>
                  <v-container v-if="codigoSeguridad == true" class="">
                    <v-card-subtitle class="" v-if="whatsapp == true">Para continuar ingresa el código de seguridad que te hicimos llegar a tu <strong>WhatsApp</strong></v-card-subtitle>
                    <v-card-subtitle v-if="correo == true">Para continuar ingresa el código de seguridad que te hicimos llegar a tu <strong>correo electr&oacute;nico</strong></v-card-subtitle>
                    <v-container style="width:90%" class="my-1"> 
                      <v-otp-input
                        dark
                        length="6"
                        type="number"
                        onkeydown="javascript: return event.keyCode == 69 ? false : true"
                        @keyup.enter.native="comprobarCodigo" v-model="codeSecurity"

                      ></v-otp-input>
                  </v-container>
                    <!-- <v-text-field  type="number"  class="centered-input text--darken-3 mt-3" 
                     @keyup.enter.native="comprobarCodigo" v-model="codeSecurity" 
                      label="Código de seguridad" filled rounded dense 
                      onkeydown="javascript: return event.keyCode == 69 ? false : true"
                    ></v-text-field> -->
                  </v-container>
                  <v-btn :disabled="codeSecurity.length != 6" class="my-5 mx-auto" width="80%" large dark color="cyan" @click="comprobarCodigo(codeSecurity)" >
                    Continuar 
                  </v-btn> 
              </v-row>
            </v-container> 
 
            <v-container v-if="codeValid"  fill-height fluid>  
               <v-row align="center" justify="center"> 
                  <v-card-title>¡Excelente!, ahora Ingresa tu nueva contraseña</v-card-title> 
                  <v-container style="width:90%" class="my-10 mx-auto">
                    
                  <v-container style="width:90%" class="my-2 mx-auto">
                    <v-text-field :append-icon="showPasswC1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPasswC1 ? 'text' : 'password'" label="Contraseña mínimo 8 caracteres" v-model="password1"
                        @click:append="showPasswC1 == true ? showPasswC1 = false : showPasswC1 = true;"
                        onkeydown="javascript: return event.keyCode == 32 ? false : true"
                    ></v-text-field> 
                  </v-container>

                  <v-container style="width:90%" class="my-2 mx-auto">
                    <v-text-field :append-icon="showPasswC2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPasswC2 ? 'text' : 'password'" label="Repite tu contraseña mínimo 8 caracteres"
                        @click:append="showPasswC2 == true ? showPasswC2 = false : showPasswC2 = true;"
                        v-model="password2"
                        onkeydown="javascript: return event.keyCode == 32 ? false : true"
                    ></v-text-field> 
                  </v-container>

                  </v-container>
                  <v-btn class="my-5 mx-auto" width="80%" 
                    large color="primary" @click="comprobarPasswords" >
                    Cambiar Contraseña
                  </v-btn> 
              </v-row>
            </v-container> 

          </v-card>
        </v-container> 
        <v-snackbar
        shaped
        elevation="12"
        v-model="snack"
        :timeout="4000"
        :color="snackColor"
        > {{snackText}} 
          <template v-slot:action="{attrs}">
            <v-btn
            v-bind="attrs"
            text
            @click="snack = false"
            >CERRAR</v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </v-main> 
</template>

<style lang="scss">
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }

    input[type=number] { -moz-appearance:textfield; }
</style>

<script>
  import axios from 'axios'; 
  import config from '../json/config.json'
  import AppMenu from '../components/Menu.vue';

  export default {
    name:"login", 
    data() {
      return {  
        isLoad : false,
        user:"",
        password:"",
        existeUsuario : false,
        showPass :false, 
        msgerror : "",
        isChangePassword:false,
        codeSecurity:"",
        codeValid:"",
        passwordValidos:false,
        showPasswC1 :false, 
        showPasswC2 :false, 
        password1:"",
        password2:"",
        tokenTemporal : "",
        resuser:{},
        codigoSeguridad: false,
        whatsapp:false,
        correo:false,
        codigoSeg:"",
        snack:false,
        snackColor:'',
        snackText:"",
        correoEnviado:{},
        whatsEnviado:{},
      }
    }, components: { 
      'app-menu': AppMenu, 
    },
    methods:{
      async comprobarPasswords(){
        if (this.password1.toUpperCase() == "REFIVIDRIO") {
          this.msgerror = "No puedes usar esta contraseña";
          return false;
        }
        if (this.password1 === this.password2) {
          if (this.password1.length < 8) {
            this.msgerror = "La contraseña debé contener mínimo 8 caracteres"; 
            return false;
          }
        }else{
          this.msgerror = "Las contraseñas no coinciden";
          return false;
        }
        this.msgerror = "";
        this.isLoad = true; 
        let resuser = await axios.put(
          config.apiAmoresens + "/user/changePasswordUser"
          ,{password:this.password1,isActivate:true}
          ,{headers:{'token': this.tokenTemporal}})
        .then(function (response){ 
          return response.data;
        })
        .catch(function (response){
          console.log(response);
          return false;
        }); 
        if (resuser==false) {
          this.msgerror = "Ocurrio un error, intentalo más tarde.";
        } else {
          if (resuser.status == "success") {
             this.msgerror = "";
             alert("Cambio de contraseña correcto, es necesario reiniciar tu sesión.");
             this.returnUser();
          }else{
             this.msgerror = "Ocurrio un error, contraseña no actualizada.";
          }
        } 
        this.isLoad = false;
      },
      async comprobarCodigo(code){
        this.isLoad =true; 
        let resuser = await axios.get(config.apiAmoresens + "/user/validCodSeg"
          ,{headers: { 'token': this.tokenTemporal }
          ,params: {code:this.codeSecurity,codeWhats:code}} 
        ).then(function (response){return response; })
        .catch(function (response){console.log(response);return false;});
        this.codeValid = false;
        console.log(resuser);
        if (!resuser) {
          this.msgerror = "Ocurrio un error, intenta recargar esta página";
        }else{
          if (resuser.data.status == "success" && resuser.data.data == true){
            this.msgerror = "";
            this.codeValid = true;
          } else {
            this.msgerror = "El código de seguridad es incorrecto";
          }
        }
        this.isLoad =false;  
      },
      returnUser(){
        this.existeUsuario = false;
        this.codeValid = false;
        this.isChangePassword = false;
        this.tokenTemporal = "";
        this.user = "";
        this.password = "";
        this.isChangePassword=false,
        this.codeSecurity= "";
        this.codeValid= "";
        this.passwordValidos= false;
        this.showPasswC1= false;
        this.showPasswC2= false;
        this.password1= "";
        this.password2= "";
      },
      showPassw(){ 
        this.showPass == true ? this.showPass = false : this.showPass = true;
      },
      async comprobarUsuario(){ 
        if (this.user.length < 2) {
          this.msgerror = "Por favor ingresa tu usuario."
        }else{
          this.msgerror = ""
          this.existeUsuario = await axios.get(config.apiAmoresens + "/user/exist",{params: {value:this.user}})
                              .then(function (response) 
          { 
            // console.log(response);
            if (response.data.status == "success") {
              if (response.data.data[0].result =="true" || response.data.data[0].result ==true) {
                return true;
              } else {
                return false;
              } 
            }else{
              // console.log(response.data.data);
              return false;
            }
          }).catch(function (response){console.log(response);return [];}); 
          this.existeUsuario == false ? this.msgerror = "El usuario No existe" : this.msgerror = "";
        }
      },
      
      async login(){ 
        if (this.password.length < 2) {
          this.msgerror = "Por favor ingrese su contraseña."
        }else{
          try {
            this.msgerror = ""
            this.resuser = await axios.post(config.apiAmoresens + "/user/login",{user:this.user,password:this.password })
            .then(function (response){ 
               return response;
            })
            .catch(function (response){ 
              console.log(response);
              return {status:"error",token:null,user:{"value":""}};
            });

            if (this.resuser.data.status == "success") { 
              if (this.resuser.data.user.requires_password_change) {
                this.isChangePassword = true;
                this.tokenTemporal = this.resuser.data.token; 
                
                //  this.usuarioLogin = resuser.data.user;
                //    console.log(this.usuarioLogin);
              }else{ 
                this.isChangePassword = false;
                this.msgerror = "";
                this.$cookie.set('token',this.resuser.data.token);  
                this.$cookie.set('isLogged','true');  
                location.href = "/";
              }
            }else{
              this.msgerror = "La contraseña es incorrecta";
            }
          } catch (error) {
            this.msgerror = "Existe un error, Intentalo más tarde.";
          } 
        }
      }
      ,getRandomInt() {
        return Math.floor(Math.random() * (9 - 0)) + 0;
      },
      async envioWhatsapp(user){
        this.codigoSeguridad = true;
        this.correo = false;
        this.whatsapp = true;

        await  axios.post(config.apiAmoresens + '/user/activarCuentaWhatsApp',user)
        .then(response=>{
          this.codigoSeg = response.data;
                    
        }).catch(err=>{return err})

        const paramsv2 = {
          vNombre: user.cpname,
          vCelular:user.phone2,
          vCorreo:"servicio.distribuidor@amoresens.com.mx",
          vContacto:"55-51-75-71-08",
          vCodigo:this.codigoSeg
        }
        // console.log(paramsv2);

        axios.post(config.apiWhatsApp + 'activacionCuentaAmoresens',paramsv2,
        {headers:{'access-token':'eyJhbGciOiJIUzI1NiJ9.dXN1YXJpb0FjdGl2YWNpb25BbW9yZXNlbnM.1hTj5bFHS2IK8t6LoW-NHWqTibn8rS48XCQ44OFz9WQ'}})
        .then(res=>{ 
          this.whatsEnviado = res;
        }).catch(err=>{
          return err
        })
        // console.log(this.whatsEnviado);
        // if(this.whatsEnviado.status == 'success'){
          this.snack = true;
          this.snackColor = '#31E697';
          this.snackText = "CÓDIGO ENVIADO..."
        // }

      },
      async envioCorreo(user){
        this.codigoSeguridad = true;
        this.whatsapp = false;
        this.correo = true;
         await axios.post(config.apiAmoresens + '/user/activarCuentaCorreo',user,
        {headers:{token: this.$cookie.get('token')}})
        .then(response => {
          this.correoEnviado = response.data;
          return response
        }).catch(err=>{
          console.log(err);
          return err
          })
          if (this.correoEnviado.status == 'success') {
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = "CÓDIGO ENVIADO..."
          }
      }

    },  
    async mounted() {
      window.scrollTo(0,0); 
    }, 
    async created(){ 
        this.isLoad =true;
        let userObj = await axios.get(config.apiAmoresens + "/user/userByToken", 
        {
          'headers': { 'token': this.$cookie.get('token') }
        })
        .then(res=>{return res.data;})
        .catch(err=>{return err;});  
        if (userObj.status == "success") {
          this.$router.push('/shop/Account');
        }else{
          let inputCar = document.getElementById("cantidadInCar");
          if (inputCar != null) {
            inputCar.value = "";
          }
        }
        this.isLoad =false;
    },
    
  }
</script>